@import "../../../../../assets/styles/darkLightMode/theme-variables.scss";

.optional-metrics-main-div {
    display: flex;
    flex-flow: column;
    position: relative;
    margin-top: var(--size-two);

    .note-label {
        height: fit-content;
        width: 100%;
        text-align: center;
        margin-top: var(--size-two);
    }

    .selected-table-div {

        height: fit-content;
        width: 100%;
        max-height: calc(90vh - 180px);
        overflow-y: auto;
    }

    .no-data-label {
        height: fit-content;
    }

    .optional-metrics-list {
        display: flex;
        flex-flow: column;
        width: 100%;
        margin-top: var(--size-three);

        .table-selection-div {
            width: 100%;
            display: flex;
            align-items: center;
            margin: var(--size-two) 0;

            ul {
                counter-reset: section;
                width: 100%;
                position: relative;
                display: flex;
            }

            ul .list-item-active {
                border-bottom: 1.5px solid var(--main-blue);
            }

            ul .list-item-unfinished {
                @include themify($themes) {
                    color: themed('contrast-text-color');
                    border-bottom: 1.5px solid themed('card-secondary');
                }
            }

            ul .list-item-unfinished:hover a .label {
                color: var(--main-blue)
            }

            ul .list-item-active a .label {
                @include themify($themes) {
                    color: themed('extreme-color');
                }
            }

            ul .list-item {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                width: 50%;
                flex: 1;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: start;
                -webkit-transition: border-bottom 0.3s ease-in-out;
                -o-transition: border-bottom 0.3s ease-in-out;
                -webkit-transition: border-bottom 0.3s ease-in-out;
                transition: border-bottom 0.3s ease-in-out;
                cursor: pointer;
                text-align: center;
                padding: var(--size-two) var(--size-three);
            }

            ul .list-item a {
                width: 100%;
                line-height: var(--size-four);
                height: 100%;
                display: flex;
                flex-flow: column;
                justify-content: flex-end;
                align-items: center;
            }

            ul .list-item a .label {
                display: flex;
                height: fit-content;
                margin-top: 12px;
                -webkit-transition: color 0.25s ease-in-out;
                -o-transition: color 0.25s ease-in-out;
                -webkit-transition: color 0.25s ease-in-out;
                transition: color 0.25s ease-in-out;
                justify-content: center;
                align-items: flex-end;
            }
        }
    }
}