@import "../../../assets/styles/darkLightMode/theme-variables.scss";

.create-asset-fields {
    margin-top: var(--size-three);
    display: flex;
    position: relative;
    flex-flow: column;

    .checkboxes-div {
        display: flex;
        gap: var(--size-three);
    }

    select:hover {
        @include themify($themes) {
            background-color: themed('card-secondary');
        }
    }

    .optional-fields-div {
        display: flex;
        flex-flow: column;
        width: 100%;
        height: fit-content;

        select:hover {
            @include themify($themes) {
                background-color: themed('card-secondary');
            }
        }
    }
}