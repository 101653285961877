@import "../../assets/styles/darkLightMode/theme-variables.scss";

.configurations-menu-div {
    display: flex;
    flex-flow: column;
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    border-radius: var(--border-radius);
    justify-content: center;
    padding: var(--size-two) 0;
    
    @include themify($themes) {
        background-color: themed('translucid-hover');
    }
}