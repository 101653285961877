@import "../../assets/styles/darkLightMode/theme-variables.scss";

.asset-profile-main-div {
    width: 100%;
    height: 100%;

        .title-div {
            display: flex;
            align-items: center;
            min-width: 100px;
            gap: var(--size-four);

            .title {
                white-space: nowrap;
                width: 100%;
            }

            .ticker {
                display: flex;
                align-items: flex-end;
            }
        }

    .asset-profile-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: var(--size-three);
    }
}