@import '../../../../assets/styles/darkLightMode/theme-variables.scss';

.step-one {
    text-align: left;
    box-sizing: border-box;
    padding: var(--size-four);
    border-radius: var(--border-radius);

    @include themify($themes) {
        background-color: themed('card');
    }

    .form-div {
        display: flex;
        flex-flow: column;
        gap: var(--size-three);
    }
}

.step-one textarea {
    background-color: transparent;

    @include themify($themes) {
        border: 1px solid themed('contrast-text-color');
    }

    width: 100%;
    padding: var(--size-one);
    box-sizing: border-box;
    overflow: scroll;
    border-radius: var(--border-radius);
    overflow: hidden;
    resize: none;
}

.step-one .first-input {
    min-height: 20px;
    max-height: 40px;
}

.step-one .second-input {
    min-height: 60px;
    max-height: 120px;
}

.step-one textarea:hover {
    @include themify($themes) {
        background-color: themed('card');
    }
}

.step-one textarea:focus {
    outline: none;
    border: 1px solid var;

    @include themify($themes) {
        background-color: themed('textarea');
    }
}