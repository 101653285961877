@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './darkLightMode/theme-variables.scss';

:root {
  // COLORS
  --main-blue: #0071dc;
  --main-red: red;
  --main-green: #00ab03;
  --dark-green: #008002;
  --main-orange: orange;
  --dark-blue: #0054a3;

  // GRAY SCALE
  --white: #ffffff;
  --gray-1: #fafafa;
  --gray-2: #ebebeb;
  --gray-3: #c9c9c9;
  --gray-4: #bdbdbd;
  --gray-5: #9f9f9f;
  --gray-6: #7a7777;
  --gray-7: #515559;
  --gray-8: #414141;
  --gray-9: #363636;
  --gray-10: #19232a;
  --gray-11: #06141c;

  // PADDING
  --size-one: 0.25rem;
  --size-two: 0.5rem;
  --size-three: 1rem;
  --size-four: 1.5rem;
  --size-five: 1.75rem;
  --size-six: 2rem;
  --size-seven: 2.5rem;
  --size-eight: 3rem;
  --size-nine: 4rem;
  --size-ten: 5rem;
  --size-eleven: 6rem;

  // BORDER-RADIUS
  --border-radius: 5px;
}

////////////////////////////////

// FONTS
.font-style-montserrat-family-semibold {
  font-family: 'Manrope', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 0.1px;
}

.font-style-montserrat-family-regular {
  font-family: 'Manrope', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  letter-spacing: 0.1px;
}

////////////////////////////////

// FONT SIZE
.extra-extra-extra-small-text {
  font-size: 0.5rem;
}

.extra-extra-small-text {
  font-size: 0.65rem;
}

.extra-small-text {
  font-size: 0.75rem;
}

.small-text {
  font-size: 0.9rem;
}

.medium-text {
  font-size: 1.1rem;
}

.large-text {
  font-size: 1.35rem;
}

.extra-large-text {
  font-size: 1.5rem;
}

////////////////////////////////

// FONT COLOR
.color-white {
  @include themify($themes) {
    color: themed('extreme-color');
  }
}

.color-black {
  @include themify($themes) {
    color: themed('secondary-background');
  }
}

.color-blue {
  color: var(--main-blue);
}

.color-green {
  color: var(--main-green);
}

.color-orange {
  color: var(--main-orange);
}

.color-red {
  color: var(--main-red);
}

.color-lightgray {
  @include themify($themes) {
    color: themed('secondary-text-color');
  }
}

.color-dark-gray {
  @include themify($themes) {
    color: themed('card-secondary');
  }
}

.color-transparent {
  color: transparent;
}

////////////////////////////////

// BORDER COLOR
.border-white {
  @include themify($themes) {
    border: 1px solid themed('extreme-color');
  }
}

.border-red {
  border: 1px solid red;
}

.border-green {
  border: 1px solid var(--main-green);
}

.border-lightgray {
  @include themify($themes) {
    border: 1px solid themed('contrast-color');
  }
}

////////////////////////////////

// OUTLINE COLOR
.outline-white {
  @include themify($themes) {
    outline: 1px solid themed('extreme-color') !important;
  }
}

.outline-red {
  outline: 1px solid var(--main-red) !important;
}

.outline-green {
  outline: 1px solid var(--main-green) !important;
}

.outline-lightgray {
  @include themify($themes) {
    outline: 1px solid themed('contrast-color') !important;
  }
}

////////////////////////////////

// BACKGROUND COLOR

.background-green {
  background-color: var(--main-green);
}

.background-blue {
  background-color: var(--main-blue);
}

.background-transparent {
  background-color: transparent !important;
}

////////////////////////////////

// HIDDEN BACKGROUND
.hidden-background {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  z-index: 8;
  opacity: 0.9;
  width: 100%;
  height: 100%;

  @include themify($themes) {
    background-color: themed('secondary-background');
  }
}

////////////////////////////////

// CHART CARDS
.one-third-chart-card-size {
  position: relative;
  height: fit-content;
  width: calc((100% / 3) - (((3 - 1) / 3) * var(--size-three)));
}

.small-small-chart-card-size {
  position: relative;
  width: calc((100% / 4) - (((4 - 1) / 4) * var(--size-three)));
}

.small-chart-card-size {
  position: relative;
  width: calc((100% / 2.25) - (((2.25 - 1) / 2.25) * var(--size-three)));
}

.medium-chart-card-size {
  position: relative;
  width: calc((100% / 2) - (((2 - 1) / 2) * var(--size-three)));
}

.big-chart-card-size {
  position: relative;
  width: calc((100% / 1.8) - (((1.8 - 1) / 1.8) * var(--size-three)));
}

.big-big-chart-card-size {
  position: relative;
  height: fit-content;
  flex: 0 0 100%;
}

.modal-card {
  border-radius: var(--border-radius);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.card-height-1 {
  height: clamp(125px, 9.5vw, 175px);
}

.card-height-2 {
  height: clamp(250px, 20vw, 350px);
}

.card-height-3 {
  height: clamp(290px, 24vw, 420px);
}

.card-height-4 {
  height: clamp(300px, 60vh, 600px);
}

.one-items {
  width: calc((100% / 1) - (((1 - 1) / 1) * var(--size-three))) !important;
}

.two-items {
  width: calc((100% / 2) - (((2 - 1) / 2) * var(--size-three))) !important;
}

.three-items {
  width: calc((100% / 3) - (((3 - 1) / 3) * var(--size-three))) !important;
}

.four-items {
  width: calc((100% / 4) - (((4 - 1) / 4) * var(--size-three))) !important;
}

.full-width {
  width: 100% !important;
}

////////////////////////////////

// BUTTONS

.button-disabled {
  opacity: 0.6;
  background-color: transparent;
  pointer-events: none;
}

.button-hover {
  background-color: transparent;
}

.button-hover:hover {
  background-color: var(--main-blue);
}

.nav-link-comp {
  text-decoration: none;
  display: flex;
  justify-content: center;
}

.opacity {
  opacity: 0.55;
}

.password-eye-button {
  outline: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  width: 20px;
  height: 100%;
  top: 0;
  background-color: transparent;
  padding: 0;
  border: none;

  &:hover {
    color: var(--main-blue)
  }
}

.close-90-deg {
  transform: rotate(90deg);
}

.close-180-deg {
  transform: rotate(-180deg);
  transition: transform 0.5s ease-in-out;
  -webkit-transition: transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  -webkit-transition: transform 0.5s ease-in-out;
}

////////////////////////////////

/// ALIGNMENTS

.right-alignment {
  justify-content: flex-end !important;
}

////////////////////////////////

/// DIVIDERS

.divider {
  height: 1px;
  width: 100%;
  box-sizing: border-box;

  @include themify($themes) {
    background-color: themed('card-secondary');
  }
}

////////////////////////////////

/// MARGINS

.margin-center {
  margin-right: auto !important;
  margin-left: auto !important;
}

.margin-left-auto {
  margin-left: auto !important;
}

.margin-right-auto {
  margin-right: auto !important;
}

.margin-top-1 {
  margin-top: var(--size-two) !important;
}

.margin-top-2 {
  margin-top: var(--size-three) !important;
}

.margin-top-3 {
  margin-top: var(--size-four) !important;
}

.margin-top-4 {
  margin-top: var(--size-six) !important;
}

.margin-bottom-1 {
  margin-bottom: var(--size-two) !important;
}

.margin-bottom-2 {
  margin-bottom: var(--size-three) !important;
}

.margin-bottom-3 {
  margin-bottom: var(--size-four) !important;
}

.margin-bottom-4 {
  margin-bottom: var(--size-six) !important;
}

.margin-left-1 {
  margin-left: var(--size-two) !important;
}

.margin-left-2 {
  margin-left: var(--size-three) !important;
}

.margin-left-3 {
  margin-left: var(--size-four) !important;
}

.margin-left-4 {
  margin-left: var(--size-six) !important;
}

.margin-right-1 {
  margin-right: var(--size-two) !important;
}

.margin-right-2 {
  margin-right: var(--size-three) !important;
}

.margin-right-3 {
  margin-right: var(--size-four) !important;
}

.margin-right-4 {
  margin-right: var(--size-six) !important;
}

////////////////////////////////

form {
  width: 100%;
}

// MULTI TAB

.multi-tab-main-div {
  width: 100%;
  display: flex;
  align-items: center;
  margin: var(--size-four) 0 var(--size-three) 0;

  ul {
    width: 100%;
    counter-reset: section;
    position: relative;
    display: flex;
  }

  ul li {
    align-items: start;
    -webkit-transition: border-bottom 0.3s ease-in-out;
    -o-transition: border-bottom 0.3s ease-in-out;
    -webkit-transition: border-bottom 0.3s ease-in-out;
    transition: border-bottom 0.3s ease-in-out;
    cursor: pointer;
    text-align: center;
    padding: var(--size-two) var(--size-six);
  }

  ul li:last-child {
    flex: 1 1 0px;
    cursor: initial;
  }

  .form-stepper .form-stepper-active {
    border-bottom: 1.5px solid var(--main-blue);
  }

  .form-stepper .form-stepper-unfinished {
    @include themify($themes) {
      color: themed('contrast-text-color');
      border-bottom: 1.5px solid themed('card-secondary');
    }
  }

  .form-stepper .form-stepper-unfinished:hover a .label {
    color: var(--main-blue);
  }

  .form-stepper .label {
    display: flex;
    height: 100%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-end;

    @include themify($themes) {
      color: themed('contrast-color');
    }
  }

  .form-stepper .form-stepper-active .label {
    -webkit-transition: color 0.25s ease-in-out;
    -o-transition: color 0.25s ease-in-out;
    -webkit-transition: color 0.25s ease-in-out;
    transition: color 0.25s ease-in-out;

    @include themify($themes) {
      color: themed('extreme-color');
    }
  }

  .form-stepper a {
    width: 100%;
    line-height: var(--size-four);
    height: 100%;
  }
}

////////////////////////////////

// MULTI STEP

.multi-step-main-div {
  width: 100%;
  display: flex;
  align-items: center;

  ul {
    counter-reset: section;
    width: 100%;
    position: relative;
    display: grid;

    &.ul-three-columns {
      grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    }

    &.ul-five-columns {
      grid-template-columns: 13% 25% 25% 15% 22%;
    }
  }

  ul li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-transition: border-bottom 0.3s ease-in-out;
    -o-transition: border-bottom 0.3s ease-in-out;
    -webkit-transition: border-bottom 0.3s ease-in-out;
    transition: border-bottom 0.3s ease-in-out;
    cursor: pointer;
    text-align: center;
    padding: var(--size-two) var(--size-three);
  }

  .form-stepper .form-stepper-active {
    border-bottom: 1.5px solid var(--main-blue);
  }

  .form-stepper .form-stepper-unfinished {
    @include themify($themes) {
      color: themed('contrast-text-color');
      border-bottom: 1.5px solid themed('card-secondary');
    }
  }

  .form-stepper .form-stepper-unfinished:hover a .label {
    color: var(--main-blue)
  }

  .form-stepper .label {
    display: flex;
    height: 100%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-end;

    @include themify($themes) {
      color: themed('contrast-color');
    }
  }

  .form-stepper .form-stepper-active .label {
    -webkit-transition: color 0.25s ease-in-out;
    -o-transition: color 0.25s ease-in-out;
    -webkit-transition: color 0.25s ease-in-out;
    transition: color 0.25s ease-in-out;

    @include themify($themes) {
      color: themed('extreme-color');
    }
  }

  .form-stepper a {
    width: 100%;
    line-height: var(--size-four);
    height: 100%;
  }
}

////////////////////////////////

// CHART ITEMS
.legend-items-piechart {
  line-height: 25px;
}

.treemap-rectangle:hover {
  opacity: 0.75;
}

.custom-tooltip {
  border-radius: 4px;
  white-space: nowrap;
  outline: none;
  display: flex;
  gap: 2px;
  width: fit-content;

  p {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: center;
  }

  .left-col {
    background-color: var(--main-blue);
    width: 100%;
    color: var(--gray-1);
    padding: 0 var(--size-two);
  }

  .right-col {
    width: 100%;
    height: 30px;
    padding: 0 var(--size-two);

    @include themify($themes) {
      background-color: themed('contrast-color');
      color: themed(card);
    }
  }
}

.custom-tooltop-rows-div {
  display: flex;
  gap: 2px;
  flex-flow: column;
}

////////////////////////////////

// ALERT BOX
.alert-box-div {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 50%;
  transform: translate(-50%, -120%);
  transition: transform 1s;
  -webkit-transition: transform 1s;
  -o-transition: transform 1s;
  -webkit-transition: transform 1s;
}

////////////////////////////////

.content-main-div {
  width: 95vw;
  justify-content: flex-start;
  height: fit-content;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: var(--size-three) auto var(--size-five) auto;
}

.MainDiv {
  @include themify($themes) {
    background-color: themed('secondary-background');
  }
}

.TooSmallMessage {
  @include themify($themes) {
    background-color: themed('secondary-background');
    color: themed('contrast-color');
  }
}

.RegulationsMenuButton {
  @include themify($themes) {
    background-color: themed('card');
    color: themed('contrast-color');
  }
}

.RegulationsMenuButton:hover {
  @include themify($themes) {
    background-color: var(--main-blue);
  }

  color: white;
}

.Ul {
  @include themify($themes) {
    background-color: themed('translucid-hover');
  }
}

.password-div {
  position: relative;
}

////////////////////////////////

// REACT-SELECT 

.search-bar-control-react-select {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &:focus {
    border: 1px solid var(--main-blue);
  }

  .icon {
    margin-left: var(--size-one);
  }
}

.normal-control-react-select {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  margin: 0;
  justify-content: space-between;
}

.listOptionIsMulti {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  gap: var(--size-two);
  justify-content: flex-start;
  height: 40px;
  align-items: center;

  p {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    @include themify($themes) {
      color: themed('info-card-text');
    }

    &:hover {
      @include themify($themes) {
        color: themed('contrast-text-color')
      }
    }
  }
}

.is-multi-selected-options-div {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-one);
  max-height: 7rem;
  min-height: 30px;
  overflow-y: auto;
}

.listOptionSingle {

  p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include themify($themes) {
      color: themed('extreme-color')
    }
  }

}

/// navbar
.navbar-dropdown-search-bar {
  width: 15rem;
}

@media screen and (max-width: 1200px) {
  .navbar-dropdown-search-bar {
    width: 12rem;
  }
}

@media screen and (max-width: 1000px) {
  .navbar-dropdown-search-bar {
    width: 8rem;
  }
}

@media screen and (max-width: 900px) {
  .navbar-dropdown-search-bar {
    width: 6.5rem;
  }
}

////////////////////////////////

//SEARCH BAR

.normal-search-bar {
  position: relative;
  display: inline-block;
  height: fit-content;
  width: 16rem;

  .search-bar-input {
    height: 35px;
    padding-left: 28px;
    border: none;
    width: calc(100%);
    border-radius: var(--border-radius);
    outline: none;

    @include themify($themes) {
      background-color: transparent;
      border: 1px solid themed('contrast-color');
    }
  }

  .search-bar-input:focus {
    border: 1px solid var(--main-blue);
  }

  .search-bar-icon {
    position: absolute;
    left: var(--size-two);
    top: 50%;
    transform: translate(0%, -50%);
  }

  .search-bar-input:focus+.search-bar-icon {
    @include themify($themes) {
      color: themed('extreme-color');
    }
  }
}

////////////////////////////////

@media screen and (max-width: 1200px) {
  .content-main-div {
    width: 100%;
    margin: 0;
  }
}

////////////////////////////////

@media screen and (max-width: 900px) {
  .one-third-chart-card-size {
    width: calc((100% / 1) - (((1 - 1) / 1) * var(--size-three)));
  }

  .small-small-chart-card-size {
    width: calc((100% / 2) - (((2 - 1) / 2) * var(--size-three)));
  }

  .small-chart-card-size {
    width: calc((100% / 1) - (((1 - 1) / 1) * var(--size-three)));
    height: 300px;
  }

  .medium-chart-card-size {
    height: 300px;
    width: calc((100% / 1) - (((1 - 1) / 1) * var(--size-three)));
  }

  .big-chart-card-size {
    height: 300px;
    width: calc((100% / 1) - (((1 - 1) / 1) * var(--size-three)));
  }

  .big-big-chart-card-size {
    height: 300px;
    width: calc((100% / 1) - (((1 - 1) / 1) * var(--size-three)));
  }
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  display: none;
}

.show-last-line {
  .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
    display: block;
  }
}