.double-text-card-div ul {
    list-style-type: circle;
    margin: var(--size-two) 0 var(--size-four) var(--size-four);
}

.double-text-card-div ul li {
    height: var(--size-four);
    line-height: var(--size-four);
}

.double-text-card-div p {
    margin-top: var(--size-two);
}

.double-text-card-div p:first-child {
    margin-top: 0;
}