@import "../../assets/styles/darkLightMode/theme-variables.scss";

.manage-simulations-div {
    width: 100%;

    .no-simulations-found-div {
        display: flex;
        width: 100%;
        height: 80px;
        justify-content: center;
        align-items: center;
    }

    .table-div {
        width: 100%;
        margin: var(--size-two) 0;

        .local-loader-div {
            height: 150px;
        }

        .status-div {
            display: flex;
            align-items: center;
            height: 40px;
            width: fit-content;
            gap: var(--size-one);

            .icon-div {
                height: 25px;
                width: 25px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .ready {
                color: var(--main-green);
            }

            .calculating {
                color: var(--main-orange);
            }
        }

        .table-row-cell {
            @include themify($themes) {
                background-color: themed('card');
                border-top: 2.5px solid themed('secondary-background');
                border-bottom: 2.5px solid themed('secondary-background');
            }

            height: 40px;
            padding: var(--size-two) var(--size-three);

            .simulation-details-button {
                background-color: transparent;
                border: none;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }

            .simulation-details-button:hover {
                color: var(--main-blue);
            }
        }

        .table-row-cell.limit-width {
            max-width: 30vw;
        }

        .table-head {
            @include themify($themes) {
                background-color: themed('secondary-background');
            }

            border: none;
            padding: var(--size-one) var(--size-three);

            .header-cell:hover {
                color: var(--main-blue) !important;
            }

            .active {
                @include themify($themes) {
                    color: themed('secondary-text-color')
                }
            }

            .sort-icon-active {
                color: var(--main-blue);
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .manage-simulations-div {
        .table-div {

            .status-div {
                justify-content: center;

                .icon-div {
                    margin-right: 0;
                }

                p {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .manage-simulations-div {
        .table-div {
            .table-row-cell.limit-width {
                max-width: 25vw;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .manage-simulations-div {
        .table-div {
            .table-row-cell.limit-width {
                max-width: 20vw;
            }
        }
    }
}


@media screen and (max-width: 830px) {
    .manage-simulations-div {
        .table-div {
            .table-row-cell.limit-width {
                max-width: 15vw;
            }
        }
    }
}

@media screen and (max-width: 775px) {
    .manage-simulations-div {
        .table-div {
            .table-row-cell.limit-width {
                max-width: 10vw;
            }
        }
    }
}



@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}