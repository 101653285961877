@import "../darkLightMode/theme-variables.scss";

// CARDS
.CardProperties {
  @include themify($themes) {
    background-color: themed('card');
  }
}

//////////////////////

// BUTTONS
.button-disabled {
  opacity: 0.6;
  background-color: transparent;
  pointer-events: none;
}

.button-hover {
  background-color: transparent;
}

.button-styled {
  display: flex;
  width: fit-content;
  background-color: transparent;
  white-space: nowrap;
  padding: var(--size-three) var(--size-four);
  justify-content: center;
  align-items: center;
  height: 35px;

  &.border-icon-text-button {
    border: 1px solid var(--main-blue);
    border-radius: var(--border-radius);
    gap: 10px;

    &:hover {
      background-color: var(--main-blue) !important;
    }
  }

  &.no-border-icon-text-button {
    border-radius: var(--border-radius);
    border: none;
    gap: 10px;

    &:hover {

      .icon,
      p {
        color: var(--main-blue) !important;
      }
    }
  }

  &.no-border-text-button {
    border: none;

    &:hover {
      p {
        color: var(--main-blue) !important;
      }
    }
  }

  &.text-border-button {
    border: 1px solid var(--main-blue);
    border-radius: var(--border-radius);

    &:hover {
      background-color: var(--main-blue) !important;
    }
  }

  &.border-text-full-width-button {
    border: 1px solid var(--main-blue);
    border-radius: var(--border-radius);
    width: 100% !important;

    &:hover {
      background-color: var(--main-blue) !important;
    }
  }

  &.border-text-icon-full-width-full-height-button {
    border-radius: var(--border-radius);
    padding: var(--size-six) var(--size-four);
    width: 100%;
    flex: 1;
    justify-content: space-between;

    @include themify($themes) {
      border: 1.5px solid themed('textarea');
    }

    p {
      max-width: 90%;
      line-height: var(--size-four);
      text-align: left;
      white-space: wrap;
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('textarea');
      }
    }
  }

  &.border-icon-text-full-width-button {
    border: 1px solid var(--main-blue);
    border-radius: var(--border-radius);
    width: 100% !important;
    gap: var(--size-two);
    justify-content: flex-start;
    height: 40px;

    .icon {
      transition: transform 0.25s ease-in-out;
      -webkit-transition: transform 0.25s ease-in-out;
      -moz-transition: transform 0.25s ease-in-out;
      -o-transition: transform 0.25s ease-in-out;
    }

    &:hover {
      background-color: var(--main-blue) !important;
    }
  }

  &.no-border-text-icon-full-width-button {
    border: none;
    padding: var(--size-one);
    width: 100% !important;
    gap: var(--size-two);
    justify-content: flex-start !important;

    @include themify($themes) {
      border-bottom: 1px solid themed('card-secondary');
    }

    &:hover {

      .icon,
      p {
        color: var(--main-blue) !important;
      }
    }
  }

  &.no-border-icon-button {
    padding: var(--size-three) var(--size-two) !important;
    border: none;
    z-index: 2;

    &:hover {
      .icon {
        color: var(--main-blue) !important;
      }

      .icon-orange {
        color: var(--main-orange) !important;
      }

      .icon-red {
        color: var(--main-red) !important;
      }
    }
  }

  &.no-border-text-underline-button {
    border: none;
    padding: 0;
    height: 20px;
    text-decoration: underline;

    &:hover {
      color: var(--main-blue);
    }
  }

  &.background-straight-angles-full-width-icon-text-button {
    background-color: var(--main-blue);
    width: 100% !important;
    border: none;
    gap: var(--size-two);

    &:hover {
      background-color: var(--dark-blue);
    }
  }

  &.background-straight-angles-full-width-icon-button {
    background-color: var(--main-blue);
    width: 100%;
    border: none;

    &:hover {
      background-color: var(--dark-blue);
    }
  }

  &.expandable-left-menu-top-button {
    height: 50px;
    border: none;
    width: 100% !important;
    justify-content: space-between;
    padding: 0 var(--size-four);

    p {
      width: 100%;
      text-align: left;
      margin-left: var(--size-one) !important;
    }

    .icon {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {

      .icon,
      p {
        color: var(--main-blue) !important;
      }
    }
  }

  &.expandable-left-menu-tab-button {
    padding: 0 var(--size-four);
    justify-content: flex-start !important;
    border: none;
    height: 40px;
    width: 100%;

    p {
      white-space: nowrap;
      margin-left: var(--size-three);
    }

    .icon {
      display: flex;
      height: 100%;
      padding: 0 var(--size-one);
      justify-content: center;
      align-items: center;
    }

    .status-icon {
      display: flex;
      height: 100%;
      padding: 0 var(--size-one);
      justify-content: center;
      align-items: center;
    }

    &.active {
      @include themify($themes) {
        background: themed('lines');
      }

      border-right: 2px solid var(--main-blue);
    }

    &.inactive {
      border-right: 2px solid transparent;
    }

    &.inactive:hover p,
    &.inactive:hover .icon {
      color: var(--main-blue) !important;
    }
  }
}

.scroll-to-top-button {
  position: fixed;
  height: var(--size-seven);
  width: var(--size-eight);
  bottom: var(--size-seven);
  right: var(--size-seven);
  border-radius: var(--border-radius);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12;
  mix-blend-mode: difference;
  opacity: 0;
  visibility: hidden;

  @include themify($themes) {
    border: 1.5px solid themed('contrast-color');
    color: themed('contrast-color');
  }

  &:hover {
    background-color: var(--main-blue);
    color: white;
    border: none;
  }
}

//////////////////////

/// INPUTS

input,
textarea {
  height: 35px;
  padding-left: var(--size-two);
  border-radius: var(--border-radius);
  border: none;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--gray-4);

  @include themify($themes) {
    outline: 1px solid themed('contrast-color');
  }

  &:focus {
    outline: 2px solid var(--main-blue);
  }
}

input[type="checkbox"],
input[type="radio"] {
  outline: none !important;
  padding: 0 !important;
}

textarea {
  padding-top: var(--size-two);
  resize: vertical;
  min-height: 200px;
}

////////////////////// 

/// REACT-SELECT

// react-select dropdown list separator header styling //
.group-heading-styles-div {
  padding: var(--size-one) 0px;
  display: flex;
  border-radius: 0;
  height: 20px;
  align-items: center;

  @include themify($themes) {
    background-color: themed('card-secondary');
  }
}

.is-multi-option-box {
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 3px;

  @include themify($themes) {
    background-color: themed('secondary-text-color');
  }

  border-radius: var(--border-radius);
  gap: var(--size-two);
}

.fund-asset-option-value {
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: var(--size-three);
  padding: var(--size-two);

  @include themify($themes) {
    border-top: 0.5px solid themed('secondary-text-color');
    border-bottom: 0.5px solid themed('secondary-text-color');
  }

  .left-block {
    display: flex;
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
  }

  .right-block {
    height: 100%;
    align-items: center;
    width: 100%;
    min-width: 100px;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .name {
      height: 70%;
      line-height: 200%;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      align-items: center;
      text-overflow: ellipsis;

      @include themify($themes) {
        color: themed('extreme-color')
      }
    }

    .secondary-info {
      height: 30%;
      width: 100%;
      align-items: flex-end;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include themify($themes) {
        color: themed('extreme-color')
      }
    }
  }
}


////////////////////// 