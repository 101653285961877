@import '../../../assets/styles/darkLightMode/theme-variables.scss';

.composable-map-world-full-height {
    height: 95%;
    margin: 0 auto;

    .composable-map {
    height: 100%;
    }
}

.composable-map-world-small-height {
    height: 85%;
    margin: var(--size-one) auto 0 auto;

    .composable-map {
        height: 100%;
    }
}

.composable-map-world-div {
    position: relative;
    width: 100%;

    .composable-map {
        @include themify($themes) {
            border: 2px solid themed('card-secondary')
        }

        width:100%;
        border-radius: var(--border-radius);
    }

    #map-tooltip {
        background-color: var(--main-blue);
        border-radius: 0 0 var(--border-radius) 0;
    }
}