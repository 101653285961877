@import "../../assets/styles/darkLightMode/theme-variables.scss";

.metric-question-box-div {
    justify-content: center !important;
    align-items: center !important;

    @include themify($themes) {
        background-color: themed('translucid-hover') !important;
    }

    p {
        display: flex;
        align-items: center;
        height: 100%;
        line-height: var(--size-four);
        margin-right: var(--size-two);
        flex-wrap: wrap;
    }

    .box-two-values {
        display: flex;
        gap: var(--size-two);

        .value {
            display: flex;

            .icon {
                margin: 0 var(--size-one) 0 var(--size-two);
            }
        }
    }
}

.metric-question-graph-modal {
    box-sizing: border-box;
    height: 100%;
    padding: var(--size-four);
    width: 100%;
    display: flex;
    flex-flow: column;

    .double-graph-modal-div {
        flex-flow: column;
        display: flex;
        width: 100%;
        height: 100%;

        .top-title:first-child {
            margin: 0 auto var(--size-two) auto;

        }

        .top-title {
            margin: var(--size-four) auto var(--size-two) auto;
        }
    }
}