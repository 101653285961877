@import "../../../../../../assets/styles/darkLightMode/theme-variables.scss";

.optional-metrics-selection-div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    margin: var(--size-two) 0;

    table {
        height: fit-content;
        text-align: left;
        width: 100%;
    }

    table tbody tr .checkbox-td {
        text-align: center;
    }

    .hovered-row {
        @include themify($themes) {
            background-color: themed('translucid-hover');
        }

        cursor: pointer;
    }

    .unhovered-row {
        cursor: pointer;
    }

    table tbody tr td:first-child {
        border-left: none !important;
    }

    table tbody tr td:last-child {
        border-right: none !important;
    }

    table tbody {
        @include themify($themes) {
            border-bottom: 1px solid themed('contrast-text-color') !important;
        }
    }

    table tbody:last-child {
        @include themify($themes) {
            border-bottom: 1px solid themed('contrast-text-color') !important;
        }
    }

    table td {
        padding: 0.75rem;
        vertical-align: middle;

        @include themify($themes) {
            border-top: 1px solid themed('lines');
            border-left: 1px solid themed('card-secondary');
            border-right: 1px solid themed('card-secondary');
        }
    }

    table td .value-div {
        display: flex;
        align-items: center;
    }

    table th {
        padding: var(--size-two) 0.75rem;
        border-top: 1px solid var(--main-blue);
        border-bottom: 1px solid var(--main-blue);
        vertical-align: middle;

        @include themify($themes) {
            border-left: 1px solid themed('card-secondary');
            border-right: 1px solid themed('card-secondary');
        }
    }

    table .col-small {
        width: 5%;
    }

    table .col-medium {
        width: 35%;
    }

    table .col-big {
        width: 60%;
    }

    table th:first-child {
        @include themify($themes) {
            border-left: none;
        }
    }

    table th:last-child {
        @include themify($themes) {
            border-right: none;
        }
    }

    .td-input {
        padding: 0px;
        height: 100%;
    }
}