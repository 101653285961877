@import '../../assets/styles/darkLightMode/theme-variables.scss';

.main-loader-container {
    @include themify($themes) {
        background-color: themed('secondary-background');
    }
    
    position: fixed;
    top:0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    p {
        opacity: 0.7;
    }
}