@import "../../../assets/styles/darkLightMode/theme-variables.scss";

.faq-content-div {
    display: flex;
    flex-wrap: wrap;
    gap: var(--size-three);
    width: 100%;

    .faq-content-card-div {
        justify-content: flex-start;
        width: calc((100% / 3) - (((3 - 1) / 3) * var(--size-three)));
    }
}

.faq-modal-div {
    display: flex;
    flex-flow: column;
    margin-top: var(--size-three);

    p {
        line-height: var(--size-four);
    }
}