@import '../../../../assets/styles/darkLightMode/theme-variables.scss';

.sfdr-table-page-menu {
    width: 100%;
    margin: 0 auto var(--size-three) auto;
    padding-top: var(--size-three);
    height: 50px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.sfdr-table-page-menu-active-div {
    grid-template-columns: 30% 30% 40%;
    display: grid;
    height: 1.5px;
    column-span: all;
    width: 100%;
    grid-column: 1 / 4;

    div {
        width: 100%;
    }

    .non-active {
        @include themify($themes) {
            background-color: themed('card-secondary');
        }
    }

    .active {
        background-color: var(--main-blue);
    }
}

.pai-no-data-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.pai-table-div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 8px 0;

    table {
        height: fit-content;
        text-align: left;
        width: 100%;
    }

    table tbody tr td:first-child {
        border-left: none !important;
    }

    table tbody tr td:last-child {
        border-right: none !important;
    }

    table tbody {
        @include themify($themes) {
            border-bottom: 1px solid themed('contrast-text-color') !important;
        }
    }

    table tbody:last-child {
        @include themify($themes) {
            border-bottom: 1px solid themed('contrast-text-color') !important;
        }
    }

    table td {
        padding: 0.75rem;
        vertical-align: middle;

        @include themify($themes) {
            border-top: 0.5px solid themed('lines');
            border-left: 1px solid themed('card-secondary');
            border-right: 1px solid themed('card-secondary');
        }
    }

    table td .value-div {
        display: flex;
        align-items: center;
    }

    table th {
        padding: var(--size-two) 0.75rem;
        border-top: 1px solid var(--main-blue);
        border-bottom: 1px solid var(--main-blue);
        vertical-align: middle;

        @include themify($themes) {
            border-left: 1px solid themed('card-secondary');
            border-right: 1px solid themed('card-secondary');
        }
    }

    table th[colspan="7"] {
        border: none;

        @include themify($themes) {
            background: themed('card-secondary');
        }

        height: 15px;
    }

    table .col-small {
        width: 10%;
    }

    table .col-medium {
        width: 16%;
    }

    table .col-big-big {
        width: 22%;
    }

    table th:first-child {
        @include themify($themes) {
            border-left: none;
        }
    }

    table th:last-child {
        @include themify($themes) {
            border-right: none;
        }
    }

    tbody:nth-child(even) td {
        @include themify($themes) {
            background-color: themed('card');
        }
    }

    textarea {
        background-color: transparent;
        border: none;
        width: 100%;
        padding: 3px var(--size-one);
        box-sizing: border-box;
        overflow: scroll;
        resize: vertical;
        max-height: 200px;
        min-height: 35px;
        height: 100%;
        overflow: hidden;
    }

    .td-input {
        padding: 0px;
        height: 100%;
    }

    textarea:hover {
        @include themify($themes) {
            background-color: themed('translucid-hover');
        }
    }

    textarea:focus {
        outline: 1px solid var(--main-blue);
        border: none;

        @include themify($themes) {
            background-color: themed('lines');
        }
    }
}