// LIGHT & DARK MODE
$themes: (
    light: (secondary-background: var(--gray-1),
        card: var(--gray-2),
        card-secondary: var(--gray-5),
        main-text-color: var(--gray-11),
        secondary-text-color: var(--gray-7),
        translucid-hover: var(--gray-4),
        contrast-color: var(--gray-9),
        extreme-color: var(--gray-11),
        contrast-text-color: var(--gray-6),
        lines: var(--gray-5),
        left-menu-background: var(--white),
        textarea: var(--gray-3),
        info-card-text: var(--gray-6),
        button-color: var(--gray-5),
        no-data-color: var(--gray-3),
        input: var(--gray-1),
        input-disabled: var(--gray-4),
        menu-list-background: var(--gray-2)),

    dark: (secondary-background: var(--gray-11),
        card: var(--gray-10),
        card-secondary: var(--gray-6),
        main-text-color: var(--white),
        secondary-text-color: var(--gray-5),
        translucid-hover: var(--gray-8),
        contrast-color: var(--gray-4),
        extreme-color: var(--white),
        contrast-text-color: var(--gray-4),
        lines: var(--gray-7),
        left-menu-background: var(--gray-9),
        textarea: var(--gray-6),
        info-card-text: var(--gray-2),
        button-color: var(--gray-3),
        no-data-color: var(--gray-9),
        input: var(--gray-2),
        input-disabled: var(--gray-6),
        menu-list-background: var(--gray-5))
);

@mixin themify($themes) {

    @each $theme,
    $map in $themes {
        .theme-#{$theme} & {
            $theme-map: (
                ) !global;

            @each $key,
            $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}'
                );
            $theme-map: map-merge($theme-map,
                    ($key: $value,
                    )) !global;
        }

        @content;
        $theme-map: null !global;
    }
}
}

@function themed($key) {
    @return map-get($theme-map, $key);
}