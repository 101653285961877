@import "../../assets/styles/darkLightMode/theme-variables.scss";

.overview-top-bar-div {
    width: 100%;
    z-index: 1;
    flex-flow: row !important;
    justify-content: flex-end;
    align-items: center !important;
    box-sizing: border-box;
    gap: var(--size-two);
    border-radius: var(--border-radius);

    @include themify($themes) {
        background-color: themed('card');
    }

    p {
        width: 100%;
    }
}