.regulations-content {
    width: 100%;
    display: flex;
    margin: 0 var(--size-three);
    flex-wrap: wrap;
    box-sizing: border-box;
    gap: var(--size-three);
    flex: 100%;

    .funds-dropdown {
        width: 20rem;
    }
}

@media screen and (max-width: 1200px) {
    .regulations-content {
        margin: 0 var(--size-six);
    }
}