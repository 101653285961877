@import '../../assets/styles/darkLightMode/theme-variables.scss';

#modal-window-div {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10;
    transition: transform 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    transform: translate(-50%, -50%) rotateX(90deg);


    .input-confirmation-field-div {
        display: flex;
        flex-flow: column;
        width: 100%;
        gap: var(--size-two);
    }

    .alert-box-pai-step-row-div {
        display: flex;
        align-items: center;
        margin-top: var(--size-);

        p {
            margin-left: var(--size-);
        }
    }
}

.charts-visualization {
    height: clamp(340px, calc(60vh + var(--size-seven)), 680px);
    width: clamp(420px, calc(70vw + var(--size-four)), 840px);
}

.two-charts-visualization {
    height: clamp(500px, calc(80vh + var(--size-seven)), 1000px);
    width: clamp(420px, calc(70vw + var(--size-four)), 840px);
}

.export-pai-metrics-visualization {
    height: fit-content;
    max-height: 95vh;
    width: 95vw;
}

.create-asset-create-fund {
    height: fit-content;
    max-height: 95vh;
    width: fit-content;
}

.confirmation-input-action {
    height: fit-content;
    max-width: 80vw;
    min-width: 300px;
}