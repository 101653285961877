@import '../../../assets/styles/darkLightMode/theme-variables.scss';

#composable-map-europe-div {
    position: relative;
    width: 100%;
    margin: var(--size-one) auto 0 auto;
    height: 85%;

    #composable-map {
        @include themify($themes) {
            border: 2px solid themed('card-secondary')
        }

        width:100%;
        height: 100%;
        border-radius: var(--border-radius);
    }

    #map-tooltip {
        background-color: var(--main-blue);
        border-radius: 0 0 var(--border-radius) 0;
    }
}