@import "../../assets/styles/darkLightMode/theme-variables.scss";

.configurations-page-div {
    @include themify($themes) {
        background-color: themed('secondary-background');
    }

    gap: var(--size-three);
    position:relative;
    display:flex;
    width: 100%;
}

.support-card {
    width: 100%;

    .faq-reminder {
        line-height: var(--size-four);

        a {
            text-decoration: none;
            color: var(--main-blue);
            cursor: pointer;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .form-div {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.configurations-card {
    min-height: 250px;
    gap: var(--size-four);
    align-items: flex-end !important;

    .input-small {
        @include themify($themes) {
            background-color: themed('input-disabled');
            color: themed('extreme-color');
        }

        width: calc(50% - 0.25rem);
    }

    .input-big {
        width: 100%;
        background-color: transparent;

        @include themify($themes) {
            color: themed('extreme-color');
        }
    }

    .input-disabled {
        @include themify($themes) {
            background-color: themed('input-disabled');
        }
    }
}

.configurations-main-div {
    display: flex;
    flex-wrap: wrap;
    gap: var(--size-three);
    width: 100%;

    .profile-card {
        width: calc((100% / 2) - (((2 - 1) / 2) * var(--size-three)));

        .profile-card-inside-div {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: flex-start;
            background-color: transparent;
    
            .password-div {
                position: relative;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .configurations-main-div {
        .profile-card {
            width: 100%;
        }
    }
}