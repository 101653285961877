@import "../../../assets/styles/darkLightMode/theme-variables.scss";

.create-fund-div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    gap: var(--size-three);
    box-sizing: border-box;

    .create-fund-inside-div {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column;
        box-sizing: border-box;
        gap: var(--size-three);
    }

    .fund-details-div {
        width: 100%;
        height: fit-content;
        box-sizing: border-box;
        display: flex;
        flex-flow: column;

        .regulations-note {
            line-height: var(--size-four);
        }


    }

    .create-fund-menu-div {
        display: flex;
        flex-flow: column;
        width: fit-content;
        height: fit-content;
        padding: var(--size-two) 0;
        border-radius: var(--border-radius);
        box-sizing: border-box;

        @include themify($themes) {
            background-color: themed('translucid-hover');
        }
    }

    .add-asset-div {
        display: flex;
        flex-flow: column;
        width: 100%;
        box-sizing: border-box;

        .not-found-actions-div {
            height: 50px;
            margin: var(--size-two) 0;
            gap: var(--size-one);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    .main-title {
        text-align: center;
        color: var(--gray-1);
    }

    .checkbox-field-div {
        display: flex;
        align-items: center;
        width: fit-content;
        gap: var(--size-two);

        p {
           white-space: nowrap;
        }
    }

    .added-assets-div {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        width: 100%;
        height: fit-content;
        justify-content: center;
        margin: 0 0 var(--size-two) 0;

        .label-div {
            border-left: 2px solid var(--main-blue);
        }

        .added-assets-label-div {
            display: grid;
            grid-template-columns: auto 170px 90px 120px 120px 30px;
            grid-column-gap: var(--size-two);
            width: 100%;
            height: 25px;
            box-sizing: border-box;
            margin: 0 var(--size-three);

            .added-assets-label:first-child {
                text-align: left;
            }

            .added-assets-label {
                height: 25px;
                display: flex;
                align-items: center;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

        }

        .added-assets-list-div {
            box-sizing: border-box;
                display: flex;
                flex-flow: column;
                gap: var(--size-one);
                width: 100%;
            height: 100%;

            .label-empty-assets {
                display: flex;

                @include themify($themes) {
                    background-color: themed('translucid-hover');
                }

                height: 55px;
                align-items: center;
                justify-content: center;
                width: 100%;

                .icon {
                    margin: -0.125em var(--size-one) 0 0;
                }
            }
        }
    }

    .added-asset-card {
        display: grid;
        grid-template-columns: auto 170px 90px 120px 120px 30px;
        grid-column-gap: var(--size-two);
        width: 100%;
        box-sizing: border-box;
        align-items: center;

        @include themify($themes) {
            background-color: themed('translucid-hover');
        }

        padding: var(--size-two) var(--size-two) var(--size-two) 0;

        .asset-values-details {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .created-asset-card {
        display: grid;
        grid-template-columns: auto 170px 90px 120px 120px 30px;
        grid-column-gap: var(--size-two);
        width: 100%;
        box-sizing: border-box;
        align-items: center;

        @include themify($themes) {
            background-color: themed('translucid-hover');
        }

        padding: var(--size-two) var(--size-two) var(--size-two) 0;

        .label-div {
            border-left: 2px solid var(--main-green);
        }

        .input-share:focus {
            @include themify($themes) {
                border: 1px solid themed('contrast-color');
            }
        }
    }

    .label-div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        height: 40px;
        gap: var(--size-one);
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @include themify($themes) {
            border-right: 1px solid themed('card-secondary');
        }

        .fund-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: fit-content;
            padding: 0 var(--size-three);
        }
    }

    .form-field {
        display: grid;
        box-sizing: border-box;
        grid-template-columns: 170px 350px;
        align-items: center;
        min-height: 30px;
        width: 100%;

        a:hover {
            color: var(--main-blue);
        }

        .isin-mandatry-ticker-country-message {
            color: var(--main-orange);
            display: flex;
            line-height:  var(--size-three);
            align-items: center;
        }

        .row-stripes {
            border: 2px solid var(--main-blue);

            @include themify($themes) {
                background-color: themed('contrast-text-color');
            }

            .react-datepicker__week {
                border-bottom: 1px solid var(--main-blue);
            }

            .react-datepicker__week:last-child {
                border-bottom: none;
            }
        }

        .react-datepicker__triangle {
            display: none;
        }

        .date-input {
            height: 30px;
            width: 350px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: var(--border-radius);
            box-sizing: border-box;
            background-color: transparent;
            padding: 0 var(--size-two);

            @include themify($themes) {
                border: 1px solid themed('contrast-color');
            }

            p {
                width: 100%;
                margin: 0 var(--size-two);
                text-align: left;
            }
        }

        .date-input:focus {
            border: 1.5px solid var(--main-blue);
        }

        .date-input:hover {
            @include themify($themes) {
                background-color: themed('card');
            }
        }

        label {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
        }
    }

    @media (max-width: 1050px) {
        .added-assets-div {
            .added-assets-label-div {
                grid-template-columns: auto 90px 70px 70px 70px 25px;
                grid-column-gap: var(--size-one);
            }
        }

        .added-asset-card {
            grid-template-columns: auto 90px 70px 70px 70px 25px;
            grid-column-gap: var(--size-one);
        }

        .created-asset-card {
            grid-template-columns: auto 90px 70px 70px 70px 25px;
            grid-column-gap: var(--size-one);
        }
    }

    @media (max-width: 875px) {
        .not-found-actions-div {
            width: 30%;
            flex-flow: column;
        }
    }

    @media (max-width: 900px) {

        .form-field {
            grid-template-columns: 170px 270px;

            .date-input {
                width: 270px;
            }
        }
    }
}