.unauthorized-div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    p {
        margin: var(--size-two) 0;
    }

}