@import-normalize;
@import "./assets/styles/darkLightMode/theme-variables.scss";

html,
body,
#root {
  height: 100vh;
}

html {
  background-color: #19232a;
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

/*! Firefox */
* {
  scrollbar-color: #c9c9c9 #7a7777;
  scrollbar-width: 6px;
}

/*! Rest of the browsers */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #c9c9c9;

  border-radius: var(--border-radius);
}

*::-webkit-scrollbar-thumb {
  background: #7a7777;

  border-radius: var(--border-radius);

  &:hover {
    background: #414141;
  }
}