.filter-card-company-div {
    height: fit-content;
    gap: var(--size-three);

    .filter-company-card-select-company-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .filter-company-card-three-stats {
        display: grid;
        width: 100%;
        grid-template-columns: auto auto auto;
        grid-template-rows: fit-content;
        grid-column-gap: var(--size-three);

        .filter-company-card-stat {
            border: 1px solid var(--main-blue);
            border-radius: var(--border-radius);
            height: 60px;
            display: flex;
            flex-flow: column;
            gap: var(--size-four);
            justify-content: flex-end;
            padding: var(--size-six) var(--size-three);
        }
    }
}