  @import "../../../assets/styles/darkLightMode/theme-variables.scss";

  .one-or-two-value-card {
    text-align: center;
    padding: var(--size-six) !important;

    .graph-div {
      width: 100%;
      height: 120px;
    }

    .two-value-card-value-label-top {
      height: 70%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--size-three);
    }

    .two-value-card-label-label-top {
      height: fit-content;
      text-align: center;
      width: 100%;
      height: 40px;
    }

    .two-values-card-label {
      display: flex;
      width: 100%;
      gap: var(--size-three);
      height: 100%;

      .vertical-div {
        display: flex;
        width: 100%;
        flex-flow: column;

        .label {
          text-align: center;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .horizontal-div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: var(--size-three);

        .two-value-card-value-label-top {
          width: fit-content;
        }
      }
    }

    .no-value-card-value-label-to {
      @include themify($themes) {
        background-color: themed('no-data-color');
      }

      width: 60%;
      margin: auto;
      height: 35%;
    }
  }