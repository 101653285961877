@import "../../../assets/styles/darkLightMode/theme-variables.scss";

.metrics-board-analysis-div {
    width: 100% !important;
    gap: var(--size-three);
    height: fit-content !important;

    .multi-analysis-div {
        display: flex;
        flex-flow: column;
        height: 100%;
        gap: var(--size-four);
        width: 100%;

        .top-div {
            display: grid;
            height: 100%;
            grid-template-columns: 39% 59%;
            grid-template-rows: 100%;
            grid-column-gap: 2%;
            width: 100%;

            .main-graph {
                width: 100%;
            }

            .right-div {
                display: flex;
                gap: var(--size-three);

                .questions-div {
                    height: 100%;
                    display: flex;
                    gap: var(--size-three);
                    flex-flow: column;
                }
            }
        }

        .bottom-div {
            width: 100%;
            display: grid;
            grid-template-columns: 39% 59%;
            grid-template-rows: 1fr;
            grid-column-gap: 2%;
        }
    }
}