@import "../../../../../assets/styles/darkLightMode/theme-variables.scss";

.modal-window-export-fund-report-div {
    display: flex;
    flex-flow: column;
    width: 100%;

    .table-div {
        width: 100%;
        margin-top: var(--size-three);

        .table-row-cell {
            @include themify($themes) {
                background-color: themed('translucid-hover');
                border-top: 2.5px solid themed('card');
                border-bottom: 2.5px solid themed('card');
            }

            height: 25px;
            border-radius: var(--border-radius);
            padding: var(--size-three);
            gap: var(--size-three);
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .table-row-cell:hover {
            background-color: var(--main-blue);
        }

        .table-head {
            @include themify($themes) {
                background-color: themed('card');
            }

            border: none;
            padding: var(--size-one) var(--size-four);

            .header-cell {
                margin-left: 25px;
            }

            .header-cell:hover {
                color: var(--main-blue) !important;
            }

            .active {
                @include themify($themes) {
                    color: themed('secondary-text-color')
                }
            }

            .sort-icon-active {
                color: var(--main-blue);
            }
        }
    }
}